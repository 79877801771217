import React from 'react';
import './App.css';
import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { motion } from "framer-motion"

function App() {
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <div className="app">
      <motion.header
        className="header"
        initial="hidden"
        animate="visible"
        variants={variants}
      >
        <h1>Swen Meeuwes</h1>
        <h4><span>Game developer</span></h4>
        <div id="skills">
          <div>
            C# &bull; JavaScript
          </div>
          <div>
            Unity &bull; ASP.NET Core &bull; CreateJS
          </div>
          <div>
            Scrum
          </div>
        </div>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<MailOutlineIcon />}
          href="mailto:contact@swenmeeuwes.nl"
        >
          Contact me
        </Button>
      </motion.header>
    </div>
  );
}

export default App;
